



















































import {Category} from "@/request/goods/Category";

export default {
    data() {
        let self: any = this;
        return {
            split: 0.5,
            modal: {
                show: false,
                title: '',
                id: '',
                firstId: '',
                name: '',
                onOk() {
                    if (!self.modal.firstId) {
                        Category.saveCategory({
                            id: self.modal.id,
                            name: self.modal.name
                        }).then((body: any) => {
                            self.loadCategory();
                            self.modal.id = '';
                            self.modal.name = '';
                        })
                    } else {
                        Category.saveCategorySec({
                            firstId: self.modal.firstId,
                            id: self.modal.id,
                            name: self.modal.name
                        }).then((body: any) => {
                            self.loadCategorySec(self.modal.firstId);
                            self.modal.id = '';
                            self.modal.name = '';
                        })
                    }
                }
            },
            categoryList: [],
            categorySecList: []
        }
    },
    created(): void {
        let self: any = this;
        self.loadCategory();
    },
    methods: {
        loadCategory() {
            let self: any = this;
            Category.getCategoryList().then((body: any) => {
                if (body['code'] === 0) {
                    self.categoryList = body.data;
                }
            })
        },
        loadCategorySec(id: string) {
            let self: any = this;
            if (id) {
                Category.getCategorySecList(id).then((body: any) => {
                    if (body['code'] === 0) {
                        self.categorySecList = body.data;
                    }
                })
            }
        },
        categorySelect(item: any) {
            let self: any = this;
            self.categoryList.forEach((j: any) => j.selected=false);
            item.selected = true;
            self.modal.id = item.id;
            self.modal.firstId = item.id;
            self.$forceUpdate();
            self.loadCategorySec(item.id);
        },
        editCategory(item: any) {
            let self: any = this;
            self.modal.id = item.id;
            self.modal.firstId = '';
            self.modal.name = item.name;
            self.modal.show = true;
        },
        editCategorySec(item: any) {
            let self: any = this;
            self.modal.id = item.id;
            self.modal.name = item.name;
            self.modal.show = true;
        },
        checkCategoryDel(item: any) {
            let self: any = this;
            self.categoryList.forEach((j: any) => j.check=false);
            item.check = true;
            self.$forceUpdate();
        },
        checkCategorySecDel(item: any) {
            let self: any = this;
            self.categorySecList.forEach((j: any) => j.check=false);
            item.check = true;
            self.$forceUpdate();
        },
        delCategory(id: string, index: number) {
            let self: any = this;
            Category.delCategoryByIds([id]).then((body: any) => {
                if (body['code'] ===0) {
                    self.categoryList.splice(index, 1);
                }
            })
        },
        delCategorySec(id: string, index: number) {
            let self: any =this;
            let currentCategory = self.categoryList.filter((j: any) => j.selected === true)[0];
            Category.delCategorySec({
                id: currentCategory.id,
                secId: id
            }).then((body: any) => {
                if (body['code'] === 0) {
                    self.categorySecList.splice(index, 1);
                }
            })
        }
    }
}
